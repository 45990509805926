import axios from "axios";
import { convertUrl } from "../helpers/helper";

const state = {
  user: {},
  users: [],
  usersList: [],
  paginationPages: 1,
  validationErrors: [],
  isLoading: false,
  userCharges: []
};

const mutations = {
  SET_USER(state, data) {
    state.user = data;
  },

  SET_USERS(state, data) {
    state.users = data;
  },

  SET_USER_CHARGES(state, data){
    state.userCharges = data;
  },

  ADD_USER_CHARGE(state, data){
    state.userCharges.push(data);
  },

  REMOVE_USER_CHARGE(state, index){
    state.userCharges.splice(index, 1);
  },

  SET_USERS_LIST(state, data) {
    state.usersList = data;
  },

  SET_PAGINATION_PAGES(state, data) {
    state.paginationPages = data;
  },

  SET_VALIDATION_ERRORS(state, data) {
    state.validationErrors = data;
  },

  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },
};

const actions = {
  async getUser({ commit }, { user_id }) {
    commit("SET_USER", {});
    commit("SET_IS_LOADING", true);
    try {
      const apiUrl = `user/${user_id || 0}`;
      const { data } = await axios.get(apiUrl);
      commit("SET_USER", data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },

  async addUser({ commit }, user) {
    commit("SET_USERS", [{ ...user }]);
  },


  async getUsers({ commit }, payload) {
    commit("SET_USERS", []);
    commit("SET_IS_LOADING", true);
    try {
      let apiUrl = convertUrl("user", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        let list = data.map(function (item) {
          return {
            ...item,
            name: `${item.name} ${item?.last_name_father || ""}`,
          };
        });
        commit("SET_USERS", list);
      } else {
        commit("SET_USERS", data?.result);
        commit("SET_PAGINATION_PAGES", data?.pages || 1);
      }
    } catch (error) {
      commit("SET_USERS", []);
    } finally {
      commit("SET_IS_LOADING", false);
    }
  },

  async getUsersList({ commit }, payload) {
    try {
      let apiUrl = convertUrl("user", payload);
      let { data } = await axios.get(apiUrl);
      if (Array.isArray(data)) {
        commit("SET_USERS_LIST", data);
      }
    } catch (error) {
      commit("SET_USERS_LIST", []);
    }
  },

  async saveUser({ commit }, payload) {
    try {
      commit("SET_VALIDATION_ERRORS", []);
      await axios.post("user", payload);
      // dispatch("getUsers", { page: 1 });
      return {ok: true, message: null }
    } catch (error) {
      return {ok: false, message: error.response.data.message }
    }
  },

  async savePermissions({ commit }, payload) {
    try {
      commit("SET_VALIDATION_ERRORS", []);
      await axios.post(`user/permissions`, payload);
    } catch (error) {
      if (error.response.status == 400) {
        commit("SET_VALIDATION_ERRORS", error?.response?.data?.message);
      } else {
        commit("SET_VALIDATION_ERRORS", ["No se pudo realizar está operación"]);
      }
      throw new Error(error);
    }
  },

  async updateUser({ commit }, payload) {
    try {
      commit("SET_VALIDATION_ERRORS", []);
      await axios.put(`user/${payload.id}`, payload);
      return {ok: true, message: null }
    } catch (error) {
      return {ok: false, message: error.response.data.message }
    }
  },

  async deleteUser({ dispatch }, id) {
    try {
      await axios.delete(`user/${id}`);
      dispatch("getUsers", { page: 1 });
    } catch (error) {
      throw new Error("No se pudo realizar está operación");
    }
  },

  async updatePassword({commit},payload) {
    try {
      commit("SET_USER", {});
      await axios.post(`auth/change-password`, payload);
      return {ok: true, message: null }
    } catch (error) {
      return {ok: false, message: error.response.data.message }
    }
  },

  async updateCharges({ commit }, payload) {
    try {
      commit("SET_VALIDATION_ERRORS", []);
      await axios.post(`user/update-charges`, payload);
      commit("SET_USER_CHARGES", []);
      return {ok: true, message: null }
    } catch (error) {
      return {ok: false, message: error.response.data.message }
    }
  },

  cleanValidationErrors({ commit }) {
    commit("SET_VALIDATION_ERRORS", []);
  },

  updateCurrentPage({ commit }, page) {
    commit("SET_PAGINATION_CURRENT_PAGE", page);
  },

  clearUser({commit}){
    commit("SET_USER", {});
  },

  async updateActive({commit},payload) {
    try {
      commit("SET_VALIDATION_ERRORS", []);
      await axios.post(`user/update-active`, payload);
      return {ok: true, message: null }
    } catch (error) {
      return {ok: false, message: error.response.data.message }
    }
  },

  async getUserCharges({ commit }, { user_id }) {
    try {
      commit("SET_USER_CHARGES", []);
      const apiUrl = `user/${user_id || 0}/charges`;
      const { data } = await axios.get(apiUrl);
      if(data.charges){
        commit("SET_USER_CHARGES", data.charges);
      }
    } catch (error) {
      commit("SET_USER_CHARGES", []);
    }
  },

  async softDeleteUser(__ , payload){
    try{
      const {data} = await axios.delete(`user/${payload}`);
      return {ok: true, message: data.message};
    }catch(error){
      return {ok:false, message: error.response.data.message};
    }
  },
  
  addUserCharge({commit}, payload){
    commit("ADD_USER_CHARGE", payload);
  },

  removeUserCharge({commit}, payload){
    commit("REMOVE_USER_CHARGE", payload);
  }
};

const getters = {
  getUser(state) {
    return state.user;
  },

  getUsers(state) {
    return state.users;
  },

  getUserList(state) {
    return state.usersList;
  },

  getPaginationPages(state) {
    return state.paginationPages;
  },

  getValidationErrors(state) {
    return state.validationErrors;
  },

  getUserCharges(state) {
    return state.userCharges;
  },
};

const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default user;
