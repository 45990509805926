function convertUrl(urlBase = "", items = {}) {
  let path = '';
  items = Object.entries(items);
  if (items && Array.isArray(items) && items.length > 0) {
    items.forEach((element) => {
      if (element[1] && element[1] != null && element[1] != "") {
        if (path.length == 0) {
          path += "?";
        } else {
          path += "&";
        }
        path += element[0] + "=" + element[1];
      }
    });
  }
  return urlBase + path;
}
export { convertUrl };
